.avatar {
    height: 128px;
    width: auto;
    border-radius: 50%;
}

.avatar-preview {
    width: 128px;
    background-position: 50% 50%;
    background-size: cover;
}

.avatar-small {
    height: 24px;
}

.user-avatar-name {
    display: inline-block;
    margin-right: 15px;
    line-height: 24px;
    letter-spacing: -0.0379166px;
    white-space: nowrap;
    overflow: hidden;
    font-family: $system-fonts;
    font-size: 1rem;

    > * {
        vertical-align: middle;
    }

    img {
        margin-right: 4px;
    }
}

.user-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.processMatrix {
  display: flex;

  > h3 {
    margin: 5px 5px 5px 0;
    padding: 1em 0;
    background-color: #EEEEEE;
    line-height: 40px;
    text-align: center;
    font-weight: normal;
    color: #666666;
    box-shadow: -1px -1px 0px rgba(156, 155, 155, 0.5);
    border-radius: .5rem;

    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    transform: rotate(-180deg);
    min-height: 150px;
    font-size: 1.17em;
    margin-block-start: 1em;
    display: block;
  }

  > ol {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li.processMatrix-lineSector {
    display: inline-block;
    box-shadow: 1px 1px 0px #9c9b9bf5;
    margin: 5px;
  }
}

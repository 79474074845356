.assessmentProcessProgress-quiublet, .auditProcessProgress-quiublet {
  span.badge {
    color: $white;

    &:hover {
      cursor: pointer; }

    &.success {
      background-color: lighten($success, 5%);

      &:hover {
        background-color: $success; } }

    &.failed {
      background-color: lighten($alert, 5%);

      &:hover {
        background-color: $alert; } }

    &.notapplicable {
      background-color: $not-applicable;

      &:hover {
        background-color: darken($not_applicable, 5%); } }

    &.not_auditable {
      background-color: $not-applicable;

      &:hover {
        background-color: darken($not_applicable, 5%); } }

    &.todo {
      background-color: $todo;

      &:hover {
        background-color: darken($todo, 3%); } } } }

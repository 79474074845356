.processRequirements {
  > .processRequirement-addButton,
  > form {
    margin: 2em 0;
  }
}

.processRequirement-addButton {
    margin: 3em 0 0;
}

.processRequirementsList {
  margin: 1em 0 3em;

  .processRequirement {
    position: relative;
    font-weight: 700;

    > .button {
      position: absolute;
      right: 0;
      padding: 0;
    }

    .no-requirement-answer {
      padding: 1em;
      background-color: #f2dede;
    }
  }

  .attachments {
    > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
          margin: 0 0 0.5em;
      }
    }
  }

  .icon-list-after-title {
    float: right;
    margin-left: 2em;
  }

  form .field input[type="text"] {
    font-size: 1em;
  }

  .processRequirement-addButton,
  form {
      margin: 10px 0 2em;
  }

  .processRequirementsList-childRequirement {
    margin: 0.25em 0;
  }

  .processRequirement-addButton,
  form,
  .processRequirementsList-childRequirement {
      margin-left: 1em;
  }

  > .processRequirement-addButton,
  > form {
      margin-left: 0;
  }
}

.qu-body {
  .container {
    .assessmentAnswer {
        clear: both;

        h5 {
            margin-bottom: 0.25em;
        }

        p {
            margin-top: 0.5em;
        }

        form {
            display: inline;
            margin-top: 0;
        }
    }

    .assessmentAnswer-toggle {
        color: $font;

        &:visited,
        &:hover,
        &:active,
        &:focus {
          color: $font;
        }
    }

    .assessmentAnswer-resultLast-indicator {
        font-size: 0.75rem !important;
    }

    .assessmentAnswer-toggleInactive {
      color: $font;
      cursor: default;

      &:hover {
        color: $font;
        text-decoration: none;
      }
    }

    .assessmentAnswer-bookmark {
        opacity: 0;
        background-image: url('../images/bookmark-not-set.svg');
        width: 12px;
        height: 14px;
        background-position: center;
        background-size: cover;
        margin: 4px 10px 0 5px;
        flex: none;

        &:hover {
          background-image: url('../images/bookmark-set.svg');
        }
    }

    .assessmentAnswer-bookmarkToggled {
        opacity: 1;
        background-image: url('../images/bookmark-set.svg');

        &:hover {
            background-image: url('../images/bookmark-not-set.svg');
        }
    }

    .assessmentAnswer-active .assessmentAnswer-bookmark {
        opacity: 1;
    }

    .assessmentAnswer:hover {
        background-color: $gray-100;
        .assessmentAnswer-bookmark {
            opacity: 1;
        }
    }

    .assessmentAnswer-heading {
        cursor: pointer;
        display: flex;
        padding: 15px 15px;

        .badge {
            height: fit-content;
        }
    }

    .assessmentAnswer-footer {
      cursor: pointer;
      display: flex;
      padding: 0px 15px 15px 15px;
      font-weight: bold;
    }

    .assessmentQuestion-validFrom {
      cursor: pointer;
      display: flex;
      padding: 15px 0px 15px 0px;
      font-weight: bold;
    }

    .assessment-summary .assessmentAnswer-heading,
    .audit-summary .assessmentAnswer-heading,
    .instance-quiublet .assessmentAnswer-heading,
    .instanceNorm .assessmentAnswer-heading {
      border-bottom: 1px solid $gray-200;
      padding-top: .75rem;
      padding-bottom: .75rem;
    }

    .assessmentAnswer-question {
        flex-grow: 5;

        /*
            TODO: The following two selectors are a temporal solution.
                  Styling and structure for assessment_questions => list_item
                  and assessment_answers => show need to be refactored
                  when we integrate Jens' styling!
        */
        .assessmentAnswer-details {
            border: 0;
        }
    }

    .assessmentAnswer-answers {
        padding-left: 15px;
        margin-right: 5px;
        white-space: nowrap;
    }

    .assessmentAnswer-attachmentsList {
        list-style-type: none;
        padding: 0;
        margin: -0.3em 0 0;
    }

    .assessmentAnswer-attachmentsList.no-edit {
        margin-top: 0;
    }

    .assessmentAnswer-details {
        border-bottom: 1px solid $off-white;
        margin-bottom: -1px;
        padding: 0 14px;

        >div {
            padding: 5px 0;
        }
    }

    .assessmentAnswer-detailsLink {
        padding: 0 5px;
        float: left;
    }

    .assessmentAnswer .success {
        margin-bottom: 10px;
    }

    .assessmentAnswer-status {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .assessmentAnswer-statusPassed {
        color: $success;
    }

    .assessmentAnswer-statusFailed {
        color: $alert;
    }

    .assessmentAnswer-statusOpen {
        color: $todo;
    }

    .assessmentAnswer-statusNevermind {
        color: $not-applicable;
    }

    .assessmentAnswer-statusAutomatic:before {
        @extend .fas;
        @extend .fa-sync-alt;
        padding-right: 0.5em;
        top: 0;
        font-size: 11px;
    }

    .assessmentAnswer-active {
      margin-top: -1px;
      @extend .qu-shadow;
      @extend .rounded;

      .assessmentAnswer-heading {
        border-bottom: 1px solid $off-white;
      }

      &:hover {
        background-color: $white;
      }
    }

    .assessmentAnswer-audit {
        border-top: 1px solid $off-white;
    }

    .assessmentAnswer-buttonUpload {
        position: relative;
        overflow: hidden;
    }

    .assessmentAnswer-buttonUpload input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: $white;
        display: block;
    }

    .assessmentAnswer-remark .form,
    .assessmentAnswer-auditReason .form {
        display: none;
    }

    .assessmentAnswer-result {
        span.assessmentAnswer-statusAutomatic:before {
            content: '';
            padding-right: 0;
        }

        form {
            display: inline-block;
            margin-right: 0.5em;
            padding-bottom: 12px;
        }
    }

    .assessmentAnswer-resultLast {
        font-size: 0.8em;
    }

    .assessmentAnswer-done {
        margin: 10px 0;
    }

    .assessmentAnswer-remarkText,
    .assessmentAnswer-auditReasonText,
    .assessmentAnswer-attachmentsText {
        hyphens: auto;

        a {
            word-break: break-all;
        }
    }

    .assessmentAnswer-auditReasonHint {
        font-style: italic;
    }

    .assessmentAnswer-auditReasonHintAlert {
        color: $alert;
    }
  }
}

.bookmarkList {
  margin-top: 4rem;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.qu-login {
  .login-bg {
    background: no-repeat center/cover url('../images/login/qu_login2.jpg'); }

  .login-custom-bg {
    background: no-repeat center/cover; }

  form.new_user, form.edit_user {
    max-width: 300px;
    margin: auto; }

  .alert {
    max-width: 480px;
    margin: auto; }

  .login-header {
    .alert-danger {
      color: darken($primary-light, 5%);
      background-color: lighten($primary-light, 45%); } }

  form .field .field_with_errors > label {
    color: $alert-light; }

  form .field .field_with_errors > input {
    border-color: $alert-light; }

  @include media-breakpoint-up(xxl) {
    form.new_user, form.edit_user {
      max-width: 400px; } } }

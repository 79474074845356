.csr-statusCounter {
  > * {
    font-size: 12px;
    font-weight: bold;
    background-color: $light-gray;
    margin: 0 4px;
    padding: 4px 8px;
    border-radius: 90px; }

  .quadrant-passed, .quadrant-empty {
    color: $white; }

  .quadrant-passed {
    background-color: lighten($dashboard-green, 7%); }

  .quadrant-empty {
    background-color: lighten($dashboard-ochre, 7%); } }

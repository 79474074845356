.assessmentQuestion {
  display: block;

  &:hover {
    text-decoration: none;

    &:after {
      content: ' (bearbeiten)'
    }
  }
}

.no-requirement-answer {
  padding: 1em;
  background-color: #f2dede;
}

.assessmentQuestion-listItem {
  width: 710px;
  line-height: 27px;

  &:hover {
    background-color: $background_box;

    > .assessmentQuestion-new {
      opacity: 1;
    }
  }
}

.assessmentQuestion-focus {
  display: none;
}

.assessmentQuestion-new {
  display: block;
  opacity: 0;
  border-bottom: 1px dotted $border;

  &:hover {
    text-decoration: none;
    opacity: 1;
  }

  &.assessmentQuestion-focus {
    display: none;
  }
}

.assessmentQuestion:after,
.assessmentQuestion-new {
  font-size: 0.8em;
  vertical-align: top;
}

table.table.assessmentQuestions {
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: none;
  }

  td {
    vertical-align: top;
  }
}

table.assessmentQuestions tr:hover {
  > td.assessmentQuestions-norms > .button,
  > td.assessmentQuestions-key-elements > .button {
    opacity: 1;
  }
}

td.assessmentQuestions-norms, td.assessmentQuestions-key-elements {
  width: 28.5%;
  padding-top: 6px;
  font-size: 0.9em;
  line-height: 1.1em;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        margin: 0 0 0.5em;
    }
  }

  > .button {
    opacity: 0;
    margin-bottom: 0.5em;

    &:hover {
      text-decoration: none;
    }
  }
}

$save_button_height: 24px;

.assessmentQuestion-form {
  position: relative;
  margin-bottom: $save_button_height;

  > button {
    position: absolute;
    right: 0;
    bottom: -$save_button_height;
  }
}

.assessment-question-relation {
  abbr {
    text-decoration: none;
  }
}

.assessmentQuestion-with-norms {
  display: flex;
  align-items: flex-start;

  > span {
    font-size: 1.1em;
  }

  > ul {
    list-style: none;
    padding-left: 0;

    li {
      white-space: nowrap;
    }
  }
}

#key-elements-modal-body .key-elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  label {
    margin-left: .5rem;
  }
}

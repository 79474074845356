.quadrant,
.quadrant-level {
    position: relative;
    width: 160px;
    height: 160px;
    background-color: #EEEEEE;
    border-radius: .5rem;
}

.processMatrix {
  .linesector-quadrant, .quadrant-level {
    width: 190px;
    height: 190px;
  }

  .quadrant-statusCounter {
    width: 190px;
  }
}

.quadrant-status-todo {
  background-color: lighten($dashboard-ochre, 20%);

  .quadrant-text {
    color: $primary;
  }

  &:hover {
    background-color: lighten($dashboard-ochre, 7%);
  }
}

.quadrant-status-passed {
  background-color: lighten($dashboard-green, 7%);

  .quadrant-text {
    color: $primary;
  }

  &:hover {
    background-color: $dashboard-green;
  }
}

.quadrant-status-failed {
  background-color: lighten($dashboard-red, 10%);

  .quadrant-text {
    color: $white;
  }

  &:hover {
    background-color: $dashboard-red;
  }
}

.quadrant-locked > .quadrant-label > .quadrant-text {
  padding-left: 15px;

  background-image: url('../images/lock.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 10px;
}

.quadrant-level.quadrant-level-1 {
    box-shadow: 1px 1px 0px #9c9b9bf5;
}

.quadrant-level.quadrant-level-2 {
    top: -2px;
    left: -2px;
    box-shadow: 1px 1px 0px #9c9b9bf5,
        2px 2px 0px $dashboard-green,
        3px 3px 0px #9c9b9bf5;

    &.quadrant-locked {
        box-shadow: 1px 1px 0px #9c9b9bf5,
            2px 2px 0px #EEEEEE,
            3px 3px 0px #9c9b9bf5;
    }
}

.quadrant-level.quadrant-level-3 {
    top: -4px;
    left: -4px;
    box-shadow: 1px 1px 0px #9c9b9bf5,
        2px 2px 0px #EEEEEE,
        3px 3px 0px #9c9b9bf5,
        4px 4px 0px #EEEEEE,
        5px 5px 0px #9c9b9bf5;

        &.quadrant-locked {
            box-shadow: 1px 1px 0px #9c9b9bf5,
                2px 2px 0px #EEEEEE,
                3px 3px 0px #9c9b9bf5,
                4px 4px 0px #EEEEEE,
                5px 5px 0px #9c9b9bf5;
        }
}

.quadrant-level.quadrant-level-4 {
    top: -6px;
    left: -6px;
    box-shadow: 1px 1px 0px #9c9b9bf5,
        2px 2px 0px $dashboard-green,
        3px 3px 0px #9c9b9bf5,
        4px 4px 0px $dashboard-green,
        5px 5px 0px #9c9b9bf5,
        6px 6px 0px $dashboard-green,
        7px 7px 0px #9c9b9bf5;

        &.quadrant-locked {
            box-shadow: 1px 1px 0px #9c9b9bf5,
                2px 2px 0px #EEEEEE,
                3px 3px 0px #9c9b9bf5,
                4px 4px 0px #EEEEEE,
                5px 5px 0px #9c9b9bf5,
                6px 6px 0px #EEEEEE,
                7px 7px 0px #9c9b9bf5;
            }
}

.quadrant-level {
    position: absolute;
    top: 0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    >* {
        width: 100%;
        text-align: center;
    }
}

.quadrant-matrix {
    @extend .mx-auto;
    border-spacing: 10px;
    border-collapse: unset;
    min-width: 750px;

    th {
        background-color: #EEEEEE;
        font-weight: normal;
        line-height: 1.1;
        color: #666666;
        box-shadow: 1px 1px 0px rgba(156, 155, 155, 0.5);
        border-radius: .5rem;
        text-align: center;
        max-width: 160px;
        height: 40px;
        padding: 5px;
    }

    thead th {
        &:first-child {
            background-color: transparent;
            box-shadow: none;
        }
    }

    tbody th {
        width: 40px;
        vertical-align: middle;

        span {
            writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            transform: rotate(-180deg);
            max-height: 150px;
        }
    }

    td {
        padding: 0;
        width: 150px;
    }
}

.quadrant-statusCounter {
    position: absolute;
    top: 13px;
    width: 160px;

    >* {
        font-size: 11px;
        font-weight: bold;
        background-color: #FFFFFF;
        margin: 2px;
        padding: 4px 8px;
        border-radius: 90px;
    }

    .quadrant-passed, .quadrant-empty {
        margin-top: -3px;
        display: inline-block;
        color: #fff;
        font-size: 20px;
        padding: 0;
        height: 30px;
        width: 30px;
        line-height: 30px;
    }

    .quadrant-passed {
        background-color: darken($dashboard-green, 10%);
    }

    .quadrant-empty {
        background-color: darken($dashboard-ochre, 10%);
    }
}

.quadrant-statusCounter-passed {
    color: $success;
}

.quadrant-statusCounter-failed {
    color: $alert;
}

.quadrant-label {
    position: absolute;
    top: 60px;
}

.quadrant-users {
    position: absolute;
    bottom: 13px;
}

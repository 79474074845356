#scene-container {
  height: 500px;
  width: 100%;
  background-color: black;
  position: relative;
}

#scene {
  height: 100%;
  width: 100%;
}

#scene-container.scene-container-fullscreen {
  height: 100%;
  width: 100%;
}

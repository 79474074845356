.collapse-btn[aria-expanded="true"] {
  .collapse-icon {
    @extend .fa-angle-double-down; } }

.collapse-btn[aria-expanded="false"] {
  .collapse-icon {
    @extend .fa-angle-double-right; } }

.sidemenu-collapse-btn[aria-expanded="true"] {
  .sidemenu-collapse-icon {
    @extend .fa-chevron-down;
    opacity: 0.85; } }

.sidemenu-collapse-btn[aria-expanded="false"] {
  .sidemenu-collapse-icon {
    @extend .fa-chevron-right;
    opacity: 0.85; } }

.accordion-button:focus {
  border-color: rgba(0, 0, 0, 0.125); }

.qu-accordion {
  .accordion-button:not(.collapsed) {
    color: $primary;
    background-color: lighten($primary-light, 52%); } }

.qu-accordion-secondary {
  .accordion-button:not(.collapsed) {
    color: $gray-700;
    background-color: $gray-100; }

  .accordion-button {
    &:focus, &:active {
      outline: none;
      box-shadow: 0 0 2px 0 $gray-200; } } }

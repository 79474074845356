.lineSector-progress {
  position: relative;
  bottom: 2px;

  span {
    &.triggered {
      background-color: lighten($primary, 5%);

      &:hover {
        background-color: $primary;
      }
    }

    &.passed {
      background-color: lighten($success, 5%);

      &:hover {
        background-color: $success;
      }
    }

    &.failed {
      background-color: lighten($alert, 5%);

      &:hover {
        background-color: $alert;
      }
    }

    &.notapplicable {
      color: $white;
      background-color: $not-applicable;

      &:hover {
        background-color: darken($not_applicable, 5%);
      }
    }

    &.open {
      background-color: $todo;

      &:hover {
        background-color: darken($todo, 3%);
      }
    }
  }
}

h3.lineSector-question {
  font-size: 18px;
  line-height: 160%;
  font-weight: normal;

  .lineSector-shortcode {
    font-weight: 700;
    padding-right: 0.5em;
  }

  .lineSector-norm {
    font-size: 12px;
    margin-right: 4px !important;
  }
  .pqc-links {
    line-height: 100%;
  }
}

.assessmentAnswer-questionLink {
  color: $primary;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }

  strong {
    margin-left: $spacer * 0.5;
    margin-right: $spacer * 0.5;
  }
}

.lineSector-processRequirementAnswerGroup {
  .assessmentAnswer {
    border-bottom: none;

    line-height: 1.25em;
  }

  .assessmentAnswer-heading {
    padding-right: 0;
    padding-left: 0;
  }

  .assessmentAnswer.assessmentAnswer-active {
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    .assessmentAnswer-heading {
      padding: 1em;
    }

    .assessmentAnswer-details {
      padding: 0 1em;
    }
  }
}

.notapplicable {
  font-weight: bold;
  color: $not_applicable;
}

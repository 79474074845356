.displayable {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 4px;
  padding: 5px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  display: flex; }

.displayable-text {
  width: 100%;
  a {
    overflow-wrap: anywhere;
    word-break: break-all; } }

.date-label {
  white-space: nowrap; }

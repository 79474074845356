.button {
  @extend .rounded;
}

.button-pair {
    min-width: 100px;
    color: $background;
}

.button-passed {
    border: 1px solid lighten($success, 5%);
    background-color: lighten($success, 5%);

    &:hover,
    &:active,
    &:focus {
        color: $background;
        background-color: darken($success, 1%);
        border-color: darken($success, 1%);
    }
}

.button-unfulfilled_customer_authorized,
.button-failed {
    border: 1px solid lighten($alert, 5%);
    background-color: lighten($alert, 5%);

    &:hover,
    &:active,
    &:focus {
        color: $background;
        background-color: darken($alert, 2%);
        border-color: darken($alert, 2%);
    }
}

.button-notapplicable {
    border: 1px solid lighten($not-applicable, 5%);
    background-color: lighten($not-applicable, 5%);

    &:hover,
    &:active,
    &:focus {
        color: $background;
        background-color: darken($not-applicable, 2%);
        border-color: darken($not-applicable, 2%);
    }
}

.button-passed.button-inactive,
.button-unfulfilled_customer_authorized.button-inactive,
.button-failed.button-inactive,
.button-notapplicable.button-inactive {
    color: darken($border, 5%);
    border: 1px solid $border;
    background-color: $background-box;

    &:hover,
    &:active,
    &:focus {
        color: $background;
    }
}

.button-passed.button-resetted,
.button-unfulfilled_customer_authorized.button-resetted,
.button-failed.button-resetted,
.button-notapplicable.button-resetted {
    color: $border;
    border: 1px solid $border;
    background-color: $background-box;
}

.button-passed.button-inactive,
.button-passed.button-resetted {
    &:hover,
    &:active,
    &:focus {
        color: $success;
        background-color: $background-success;
        border: 1px solid $background-success;
    }
}

.button-unfulfilled_customer_authorized.button-resetted,
.button-unfulfilled_customer_authorized.button-inactive,
.button-failed.button-inactive,
.button-failed.button-resetted {
    &:hover,
    &:active,
    &:focus {
        color: $alert;
        background-color: lighten($background-alert, 10%);
        border: 1px solid lighten($background-alert, 10%);
    }
}

.button-notapplicable.button-inactive,
.button-notapplicable.button-resetted {
    &:hover,
    &:active,
    &:focus {
        background-color: darken($not-applicable, 10%);
        border: 1px solid darken($not-applicable, 10%);
    }
}

.button.button-danger {
    &:hover,
    &:focus,
    &:active {
        border-color: $alert;
        background-color: $alert;
    }

    &.button-blank:hover,
    &.button-blank:focus,
    &.button-blank:active {
        color: $alert !important;
    }

    &.button-blank {
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
            background-color: transparent;
        }
    }
}

.quiub-fullscreen-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.button-transparent {
  background: transparent;
  border: 0;
  outline: 0;

  &:hover,
  &:focus {
    background: transparent;
    color: #ccc;
  }
}

.btn-primary {
  color: $white !important;
  border-color: $primary-light;
  background-color: $primary-light;

  &:hover, &:focus, &:active {
    border-color: darken($primary-light, 5%);
    background-color: darken($primary-light, 5%);
  }
}

.btn-outline-primary {
  color: darken($primary-light, 5%) !important;
  border-color: darken($primary-light, 5%);

  &:hover {
    color: $white !important;
    background-color: darken($primary-light, 5%);
  }
}

.btn-secondary {
  color: $white !important;
  border-color: lighten($secondary-dark, 10%);
  background-color: lighten($secondary-dark, 10%);

  &:hover, &:focus, &:active {
    border-color: $secondary-dark;
    background-color: $secondary-dark;
  }
}

.btn-outline-secondary {
  color: lighten($secondary-dark, 10%) !important;
  border-color: lighten($secondary-dark, 10%);

  &:hover {
    color: $white !important;
    border-color: lighten($secondary-dark, 10%);
    background-color: lighten($secondary-dark, 10%);
  }
}

.btn-outline-danger {
  color: lighten($alert-light, 5%) !important;
  border-color: lighten($alert-light, 5%);

  &:hover {
    color: $white !important;
    border-color: $alert-light;
    background-color: $alert-light;
  }
}

.btn-light:hover {
  background-color: lighten($gray-200, 2%);
  border-color: lighten($gray-200, 2%);
}

.btn-xs {
  padding: 0.08rem 0.3rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

.gray-hover:hover {
  background-color: darken($gray-300, 2%);
}

a.btn-outline-success {
  &:visited {
    color: $success;
  }
  &:hover {
    color: $white;
  }
}

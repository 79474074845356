.tab {
  overflow: hidden; }

.tab button {
  background-color: $gray-200;
  float: left;
  border: 0px;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  border-bottom: none;
  outline: none;
  cursor: pointer;
  padding: .8rem 1rem;
  margin-left: .2rem;
  transition: 0.3s;

  &:hover {
    background-color: $gray-300; } }

.tab:enabled button:hover {
  background-color: $primary-light; }

.tab button.active {
  background-color: $primary-light;
  color: $white; }

.tabcontent {
  @extend .qu-shadow;
  display: none;
  border: 1px solid $primary-light;
  padding: 1rem;
  border-radius: .2rem; }

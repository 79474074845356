.list-text {
  list-style: none;
  padding-left: 0;

  h3 {
    margin: 0 0 7px;
  }

  li {
    padding: 15px 0;
    border-bottom: 1px solid $border;

    &:last-child {
      border-bottom: none;
    }
  }
}

ul.comma-separated-list {
  display: inline;
  list-style: none;
  padding-left: 0;

  li {
    display: inline;
  }

  li:after {
    content: ", ";
  }

  li:last-child:after {
    content: "";
  }
}

.qu-body {
  .alert {
    font-weight: normal;
    border: 1px solid transparent;
    border-radius: .75rem;

    .btn-close {
      font-size: 11px;
      opacity: 0.3;
      margin-top: .2rem;

      &:hover {
        opacity: 0.5; } } }

  .alert-danger {
    color: $alert-light;
    background-color: $background-alert-light; }

  .error-messages {
    @extend .alert;
    @extend .alert-danger;

    h2 {
      font-size: $h4-font-size; }

    ul {
      margin-bottom: 0; } } }

.qu-body {
  $size_cell: 60px;
  $width_border: 2px;
  $border_radius: 10px;

  .calibrationQuiub-table {
    margin-right: auto;
    margin-left: auto;

    border-spacing: 5px;
    border-collapse: unset;

    th {
      text-align: center;
    }

    td {
      padding: 0;

      &:first-child {
        padding: .8rem .4rem;
      }
    }

    &.calibrationQuiub-tableAssign {
      margin-top: 0;
    }
  }

  .calibrationQuiub-link {
      display: inline-block;
      width: $size_cell;
      height: $size_cell;

      line-height: $size_cell;
  }

  .calibrationQuiub-locked {
      display: inline-block;
      width: 100%;
      height: 100%;

      background-image: url('../images/lock.svg');
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: 20px;

      opacity: 0.5;
  }

  .calibrationQuiub-cell {
      width: $size_cell;
      height: $size_cell;
      background-color: $background-box;
      border-radius: $border_radius;
      border-left: $width_border solid gray;
      border-bottom: $width_border solid gray;

      font-size: 1.5rem;
      text-align: center;

      > a:hover {
          text-decoration: none;
      }
  }

  .calibrationQuiub-cell.calibrationQuiub-cell-compact {
    width: $size_cell / 2;
    height: $size_cell / 2;
    border-radius: $border_radius / 2;
    padding: .5rem;

    .calibrationQuiub-link {
      display: inline-block;
      width: $size_cell / 2;
      height: $size_cell / 2 ;

      line-height: $size_cell / 2;
    }
  }

  .calibrationQuiub-cell-todo {
    background-color: lighten($dashboard-ochre, 20%);

    .calibrationQuiub-link {
      color: $primary;
    }

    &:hover {
      background-color: lighten($dashboard-ochre, 7%);
    }
  }

  .calibrationQuiub-cell-failed {
    background-color: lighten($dashboard-red, 10%);
    border-color: darken($alert, 25%);

    > a,
    > .calibrationQuiub-link {
      color: $white;
    }

    &:hover {
      background-color: $dashboard-red;
    }
  }

  .calibrationQuiub-cell-done {
    font-size: 2rem;
    background-color: lighten($dashboard-green, 7%);
    border-color: $success;

    > a,
    > .calibrationQuiub-link {
      color: darken($success, 10%);
    }

    &:hover {
      background-color: $dashboard-green;
    }
  }

  .calibrationQuiub-cell-out {
      border-color: lightgray;

      > a {
          color: darken($background-box, 5%);

          &:hover,
          &:active,
          &:focus {
              color: darken($background-box, 5%);
          }
      }

      &.calibrationQuiub-cell-failed {
          background-color: lighten($background-alert, 10%);

          > a {
              color: darken($background-alert, 5%);

              &:hover,
              &:active,
              &:focus {
                  color: darken($background-alert, 5%);
              }
          }
      }

      &.calibrationQuiub-cell-done {
          background-color: lighten($background-success, 5%);

          > a {
              color: darken($background-success, 5%);

              &:hover,
              &:active,
              &:focus {
                  color: darken($background-success, 5%);
              }
          }
      }
  }

  .calibrationQuiub-legend {
      float: right;
      width: 33%;
      border: 1px solid $border;
      padding: 1em;

      td {
          font-size: 0.75em;
          line-height: 1em;
      }

      h4 {
        font-size: 1.2rem;
      }

      h5 {
          font-size: 1.1rem;
          margin-top: 1em;
          line-height: 1em;
      }

      table {
          margin: 0.5em 0;
      }

      .calibrationQuiub-cell {
          width: $size_cell/2;
          height: $size_cell/2;
          border-radius: $border_radius/2;
          border-left: $width_border/2 solid gray;
          border-bottom: $width_border/2 solid gray;
      }
  }
}

.roleList {
  .roleList-role {
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
  }
}

.roleList-rowed {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;


  .roleList-role {
    display: flex;
    flex-basis: 33.333%;
    padding: 5px 7px;
  }
}

.roleList-selected,
.roleList-unselected {
    span {
        padding-left: 0.5em;
        vertical-align: top;
    }

    &:before {
        line-height: 24px;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.roleList-unselected {
    opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
        opacity: 1;
    }
}

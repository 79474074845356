.event-trigger-label {
  &.inactive {
    color: lighten($dashboard-ochre, 5%);
  }

  &.active {
    color: $dashboard-green;
  }

  &.complete {
    color: $alert;
  }
}

.event-trigger-ochre {
  background-color: lighten($dashboard-ochre, 20%);
}

.event-trigger-muted {
  color: darken($dashboard-ochre, 5%);

  &:hover {
    color: darken($dashboard-ochre, 15%);
  }
}

#event-trigger-edit {
  .hidden-field {
    display: none;
  }

  .event-triggers-selectize {
    min-height: 500px;
  }

  .event-trigger-quiublets {
    display: block;
    margin: 0;
    padding: 0;

    li {
      display: block;
      position: relative;
      label {
        font-weight: 400;
      }
      input[type=checkbox] {
        display: none;
      }

      .li-container input:checked ~ .checkmark-checked {
        visibility: initial;
        display: initial;
      }

      .li-container input:checked ~ .checkmark-unchecked {
        visibility: hidden;
      }

      .checkmark-checked {
        font-size: 12px;
        background-color: $primary-light;
        padding: 2px 5px;
        border-radius: 5px;
        margin-left: .75rem;
        color: $white;
        visibility: hidden;
        display: none;
        cursor: pointer;

        &:hover {
          background-color: darken($primary-light, 7%);
        }
      }

      .checkmark-unchecked {
        font-size: 12px;
        color: $primary-light;
        border: 1px solid $primary-light;
        padding: 2px 5px;
        border-radius: 5px;
        margin-left: .75rem;
        cursor: pointer;

        &:hover {
          color: $white;
          background-color: $primary-light;
        }
      }
    }
  }
}

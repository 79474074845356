.modal-bg {
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-height {
  min-height: 60vh;
}

.modal-question {
  margin: 0 0 2em;
}

.modal-question > a {
  font-size: 1.5em;
  line-height: 1.25em;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  > .fa {
    color: $font;
  }
}

.norms {
  display: grid;

  &:not(.single) {
    grid-template-columns: 25% 25% 25% 25%;
  }

  .norm-item {
    display: flex;
    margin: 10px;
  }
}

form {
  .field {
    label {
      display: inline-block;
      margin-bottom: 5px;
    }

    .hint {
      font-size: 0.75rem;
      color: $primary-light;
    }

    .field_with_errors > label {
      color: $alert;
    }

    input[type=text],
    input[type=email],
    input[type=submit],
    input[type=password],
    textarea {
      font-size: 1rem;
      box-sizing: border-box;
      width: 100%;
      display: block;
      padding: 6px 12px;
      border: 1px solid $secondary;
      border-radius: 4px;
    }

    .field_with_errors > input {
        border-color: $alert;
    }

    textarea {
      min-height: 5em;
    }
  }

  .actions {
    margin-top: 1em;
    text-align: right;
    display: flex;

    > * {
      vertical-align: middle;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }

    .radio-group {
      text-align: left;
      width: 100%;

      .options {
        margin-top: 5px;

        #option_process_group{
          margin-left: 0;
        }

        label[for=option_process_group] {
          margin-right: 7px;
        }
      }
    }
  }

  .column-broad {
    width: 60%;
  }
}

.form.form-passed {
  border: 1px solid $success;
}

.form.form-failed {
  border: 1px solid $alert;
}

.form {
    margin-top: 10px;
    padding: 19px 14px 9px;
    background-color: $background_box;
    border: 1px solid $border;
    position: relative;
    max-width: 100%;

    label {
        display: inline;
        font-weight: 700;
    }

    .form-close {
        position: absolute;
        top: 1em;
        right: 1em;
    }
}

$width_error-border: 2px;

.form-plain {
  background-color: inherit;
  border: none;
  padding: 0;
}

.required:after {
  content: " *";
}

.required-input {
  border-color: $alert-light;
}

.required-hint {
  color: $alert-light;
  font-size: 0.85rem;
}
